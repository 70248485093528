import Loop from '@material-ui/icons/Loop';
import Search from '@material-ui/icons/Search';
import { mdiArrowDecisionOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import UpxLogo from '../assets/upx.svg';
import { Particles } from '../components/Particles';

export default function Home() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  function handleSearch(value: string) {
    if (value) {
      setSearch(value);
    } else {
      setSearch('');
      setIsLoading(false);
    }
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    router.push(`/bgp-overview/${search}`);
  }

  return (
    <div className="font-roboto">
      <Particles />

      <div className="grid grid-cols-1">
        <div className="mt-16 sm:mt-10 md:mt-6 lg:mt-36 flex place-self-center place-content-center items-center">
          <UpxLogo className="transform scale-60 -m-11 md:scale-60 md:-m-10 lg:scale-90 lg:-m-2" />
          <h1 className="text-xl md:text-5xl lg:text-6xl font-saira">
            <span className="m-3 text-3xl md:m-5 md:text-6xl font-roboto text-blue">|</span>
            Tools
          </h1>
        </div>

        <h2 className="mt-4 md:mt-2 lg:mt-10 text-base md:text-xl lg:text-3xl place-self-center">
          Check the visibility of your BGP routes globally
        </h2>

        <div className="mt-16 sm:mt-10 lg:mt-36 flex place-self-center place-content-center items-center">
          <Icon path={mdiArrowDecisionOutline} className="w-1/12 md:w-1/12" />
          <h1 className="text-xl md:text-4xl">
            <span className="m-3 text-3xl md:text-5xl text-blue">|</span>
            Upstream visibility
          </h1>
        </div>

        <form
          className="w-11/12 lg:w-9/12 xl:w-6/12 2xl:w-5/12 mt-6 md:mt-6 sm:mb-20 flex place-self-center"
          onSubmit={handleSubmit}
        >
          <input
            name="query"
            className="text-base pl-6 md:text-xl flex-1 text-white focus:border-gray-light placeholder-gray-light bg-gray rounded-l-md border-t-2 border-b-2 border-l-2 border-gray-light"
            placeholder="Search for any ASN"
            type="number"
            required
            onChange={(e) => handleSearch(e.target.value)}
          />

          {!search && !isLoading && (
            <button className="p-2 md:p-3 text-gray bg-gray-lightest rounded-r-md">
              <Search fontSize="large" />
            </button>
          )}

          {search && !isLoading && (
            <button className="p-2 md:p-3 text-gray bg-blue rounded-r-md">
              <Search fontSize="large" />
            </button>
          )}

          {isLoading && search && (
            <button className="p-2 md:p-3 text-gray bg-blue rounded-r-md">
              <Loop fontSize="large" className="animate-spin" />
            </button>
          )}
        </form>
      </div>

      <footer className="p-2 md:p-3 w-full fixed bottom-0 bg-gray-darkest flex justify-end text-xl text-blue">
        <a className="text-md mr-5 text-base md:mr-10 " href="https://upx.com/" target="_blank">
          MEET UPX
        </a>

        <a className="text-md mr-5 text-base md:mr-10" href="https://upx.com/en/contact" target="_blank">
          CONTACT US
        </a>
      </footer>
    </div>
  );
}
